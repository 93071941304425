import ad from './lib/ad';
import gdprNotice from './modules/gdprNotice';
import searchForm from './modules/searchForm';

export default function() {
    $(function() {
        if ($('lottie-player').length) {
            import('@lottiefiles/lottie-player');
        }

        gdprNotice.addGDPR();

        searchForm.init($('#newsearch'));

        ad.adResize($('.ad-950'), 950);
        ad.adResize($('.ad-725'), 725);
        ad.adResize($('.ad-728'), 728);
        ad.adResize($('.ad-300'), 300);
        
        $(window).on('resize', function() {
            ad.adResize($('.ad-950'), 950);
            ad.adResize($('.ad-725'), 725);
            ad.adResize($('.ad-728'), 728);
            ad.adResize($('.ad-300'), 300);
        });

        let $cookieBannerModal = $('#cookierBannerModal');
        if ($cookieBannerModal.length) {
            $cookieBannerModal.removeClass('hide');

            $cookieBannerModal.find('.j-accept, .j-deny').one('click', function() {
                ckEventUrl($(this).attr('data-url'));
                
                let time = 525600 * 60000; // one year in miliseconds
                let vdate = new Date();
                vdate.setTime(vdate.getTime() + time);

                let selection = $(this).hasClass('j-accept') ? 'accept' : 'deny';
                document.cookie = 'dsa_cookieBanner_accepted5=' + selection + '; expires=' + vdate + '; path=/';

                $cookieBannerModal.addClass('hide');
            });
        }

        const $cookieBanner = $('#cookierBanner');
        $cookieBanner.attr('src', $cookieBanner.attr('data-src'));
    });
}

function ckEventUrl(ckEventReq) {
    let ck23f3543Timer = null;
    try {
        let ckImg = $(document.createElement('img'));
        ckImg.attr('src', ckEventReq);
        ckImg.appendTo('body');
        ckImg.addClass('hide');

        ckImg.onload = ckImg.onerror = function () {
            window.clearTimeout(ck23f3543Timer);
        };
    } catch (err) {
        window.clearTimeout(ck23f3543Timer);
    }
}